import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="MJMK Customer Story - Rotaready"
          description="Since using Rotaready, MJMK has managed to reduce its wage percentage by over 5%. Here's how..."
          url="customer-stories/hospitality-mjmk"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="MJMK"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="MJMK logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since using Rotaready, MJMK has managed to reduce its wage percentage by over 5%. Here's how..." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="5" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="140" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="607%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="24 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="MJMK" />
              </Content>

              <Content>
                <TextBlock text="MJMK was founded as a creative music events business in 2013, by co-founders Jake and Marco. Since then, the pair have broadened their repertoire and opened a number of unique bars and restaurants across London." />
              </Content>

              <Content>
                <TextBlock>
                  When the first bar was opened in November 2017, the owners implemented a staff scheduling solution to help them build staff rotas and monitor <Link style="color:inherit;" to="/time-attendance">time and attendance</Link>. However, following the opening of a second restaurant in Dec 2019, it soon became apparent that the existing solution couldn't scale with the business.
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="From inefficient to intelligent" />
              </Content>

              <Content>
                <TextBlock text="It was clunky and inefficient, with many of the features MJMK needed requiring manual input. As the business grew this manual approach was no longer feasible. So, in June 2020 MJMK looked to find a staff scheduling solution that was more intelligent, efficient and easier for the whole team to use. MJMK needed a solution to work across varied bar and restaurant businesses." />
              </Content>

              <Content>
                <TextBlock>
                  This wasn't the only criteria the business had. The chosen solution needed to give managers greater visibility and control over costs, as well as the ability to accurately report on individuals' hours and easily track holidays and absences. Upon recommendation from its <Link style="color:inherit;" to="/payroll">payroll</Link> provider, MJMK turned to Rotaready which ticked all the boxes.
                </TextBlock>
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The perfect set-up" />
              </Content>

              <Content>
                <TextBlock text="Ahead of the roll out Rotaready worked closely with MJMK to understand the needs of each of its sites, which consisted of two restaurants and a bar, to recommend the best possible configuration. The rollout then got underway and within just one week, the MJMK team were both scheduling staff rotas and clocking in and out using Rotaready." />
              </Content>

              <Content>
                <Blockquote
                  text="Each of our brands is unique and the team at Rotaready really took the time to understand our requirements and advise us as to which features we should be using across the business. That combined with the flexibility of the product itself means we've ended up with a staff scheduling solution tailored perfectly to our business."
                  attribution="Xander Cullum, Financial Controller"
                />
              </Content>

              <Content>
                <TextBlock text="A few months later MJMK launched two more brands, each with different requirements and set-ups to the existing brands. Again, the team at Rotaready worked closely with MJMK to get staff at both sites trained and using Rotaready within a week." />
              </Content>

              <Content>
                <Blockquote
                  text="The support we've had from the Rotaready team has been fantastic. In fact, I can't remember a time where my question hasn't been resolved in real-time."
                  attribution="Xander Cullum, Financial Controller"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Ongoing results" />
              </Content>
            </Section>

            <Section>
              <PopQuote text="Implementing Rotaready has given MJMK the means to easily monitor staff time, as well as full visibility over its sales vs costs and budgeted vs actual spend. This combined with automated rounding of attendance data for payroll, has helped MJMK reduce its wage percentage by over 5%; all whilst maintaining the high level of customer service the group prides itself on." />
            </Section>

            <Section>
              <Content>
                <TextBlock text="On top of this, the business now has a much more accurate way of reporting on its P&L thanks to Rotaready's ability to smooth out holiday costs over time; something that was always previously a challenge." />
              </Content>

              <Content>
                <TextBlock text="Rotaready hasn't just given MJMK greater control over its costs, it's saved General Managers a huge amount of time on admin heavy tasks. Being able to build staff rotas in real-time, approve them in just one click and easily share with employees via the Rotaready app; has saved MJMK's GM's an average of half a day every week." />
              </Content>

              <Content>
                <Blockquote
                  text="We love using Rotaready, it's so easy to build and track staff rotas. The ability to do so in real-time means we always know where we're at versus budgets."
                  attribution="Claire O'Sullivan, General Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Casa do Frango restaurant"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Looking to the future" />
              </Content>

              <Content>
                <TextBlock>
                  Rotaready will continue to work closely with the MJMK team to ensure they continue to get the most from its <Link style="color:inherit;" to="https://www.rotaready.com/rota-scheduling">staff scheduling software.</Link> Next on the agenda is to help them leverage Rotaready's intelligent demand forecasts so they can further optimise their staff rotas and labour spend.
                </TextBlock> 
              </Content>

              <Content>
                <Blockquote
                  text="I'd highly recommend Rotaready to other hospitality businesses, particularly those with multiple sites who need to quickly and accurately get control of their labour costs. The simple savings on labour easily pay for the service itself, increasing business profitability."
                  attribution="Xander Cullum, Financial Controller"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Waiter in an MJMK restaurant"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_mjmk"
                label="rotaready.com/customer-stories/hospitality-mjmk"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-MJMK.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-mjmk.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-mjmk.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/inline-mjmk1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/inline-mjmk2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
